.test_category {
    font: inherit;
    font-size: 14px;
    line-height: 26px;
    text-indent: 30px;
    margin: auto;
    font-weight: bold;
    white-space: pre;
    justify-content: space-between;
}
.date_box {
    margin-left: 30px;
    justify-content: flex-start;
}
.picker_box {
    margin-left: 30px !important;
    justify-content: space-between;
}
.tabledate_header {
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
}
.table_tag {
    white-space: nowrap;
}
.table_message {
    width: 100%;
}
.ag-header-cell-text {
    font-size: 16px;
    font-weight: bold;
    color: #2522e4;
}
div.ag-theme-balham div.ag-row {
    font-size: 14px;
    background-color: rgb(233, 243, 243);
}

.thumbnail:hover {
    position: relative;
    top: -25px;
    left: -35px;
    width: 640px;
    height: auto;
    display: block;
    z-index: 999;
}
