body,
div,
button,
a,
img {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.list_container {
    background-color: #eeeeee;
    padding: 10px 0px 10px 0px;
}

.member_card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #ffffff;
    min-height: 80px;
    margin: 25px;
    padding: 0px 0px 0px 20px;
    border-radius: 6px;
    box-shadow: 0 3px 3px 0px #dfcdcd;
}

.thumb {
    width: 100px;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    width: 200px;
    min-width: 150px;
}

.profile .name {
    padding: 20px;
    line-height: 15px;
    font-size: 20px;
    font-weight: 900;
}

.middle_tag_container {
    width: calc(100% - 550px);
    flex-direction: row;
    display: flex;
    height: 180px;
    justify-content: space-evenly;
}

.middle_tag_container .tag_list {
    padding-top: 25px;
    flex-wrap: wrap;
}

.middle_tag_container .tag_list .tag {
    border: 1px solid #000000;
    width: 50px;
    margin-right: 20px;
    margin-bottom: 10px;
    line-height: 20px;
    border-radius: 8px;
    padding: 2px;
}

.middle_tag_container .tag_list .tagempty {
    width: 50px;
    margin-right: 20px;
    margin-bottom: 10px;
    line-height: 20px;
    border-radius: 8px;
    padding: 2px;
}

.middle_tag_container .articletag {
    padding-top: 0px;
    width: 50%;
    flex-wrap: wrap;
}

.side_options {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    padding: 10px 20px 20px 20px;
}

.side_options .scores {
    display: flex;
    align-items: flex-end;
    padding: 10px;
}

.side_options .total_score {
    display: flex;
    color: #ff9999;
    font-weight: bold;
    padding-top: 10px;
    font-size: 30px;
    line-height: 20px;
}

.side_options .scores .score_icon {
    padding-left: 10px;
}

.side_options .scores .score {
    padding-left: 5px;
    line-height: 12px;
}

.side_options .options {
    padding: 10px 10px 0 10px;
}

.side_options .options .tag_button {
    width: 100px;
    height: 35px;
    color: #ffffff;
    background-color: #54ad50;
    border: none;
    border-radius: 10px;
    margin: 0px 2px 2px 0px;
    /*   position: absolute;   */
    /*   left: 10; */
    /*   bottom: 5px;     */
}

.side_options .options .option_button {
    width: 100px;
    height: 35px;
    color: #ffffff;
    background-color: #54ad50;
    border-radius: 10px;
    border: none;
    margin: 0px 2px 2px 0px;
    /*   position: absolute;   */
    /*   left: 112px; */
    /*   bottom: 5px;     */
}

.thumb img {
    max-width: 100%;
    box-shadow: 0 0 1px 1px #f4f4f4;
    border-radius: 75px;
}
