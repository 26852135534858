$primary: '#222A45';
$secondary: rgba(52, 49, 76, 0.54);
$error: rgba(255, 10, 10, 1);
$bg-default: rgba(0, 0, 0, 0.55);
$bg-paper: #222a45;
$light-gray: rgba(0, 0, 0, 0.08);
$text-muted: '#ff9e43';
$text-hint: rgba(52, 49, 76, 0.38);
$brand: $primary;
$chart: #ebecf1;
.bg-chart {
    background: $chart !important;
}
.bg-primary {
    background: $primary !important;
}
.bg-secondary {
    background: $secondary !important;
}
.bg-green {
    background-color: rgba($color: green, $alpha: 0.75) !important;
}
.bg-error {
    background: $error !important;
}
.bg-white {
    background: #fff !important;
    color: inherit;
}
.bg-default {
    background: $bg-default !important;
}
.bg-paper {
    background: $bg-paper;
}
.bg-light-gray {
    background: $light-gray !important;
}
.bg-dark {
    background: #000000;
    color: #fff;
}
.bg-light-dark {
    background: #212121;
    color: white;
}
.bg-error {
    background: $error !important;
    color: white !important;
}
.bg-green {
    background: #08ad6c !important;
}
.bg-light-primary {
    &::after {
        background: $primary;
    }
}
.bg-light-secondary {
    position: relative;
    z-index: 0;
    &::after {
        background: $secondary;
    }
}
.bg-light-error {
    position: relative;
    z-index: 0;
    &::after {
        background: rgb(241, 234, 234);
    }
}
.bg-light-green {
    background: rgba($color: #08ad6c, $alpha: 0.5) !important;
}

[class^='bg-light-'],
[class*=' bg-light-'] {
    position: relative;
    z-index: 0;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.15;
        z-index: -1;
        border-radius: 8px;
    }
}

.bg-transperant {
    background: transparent !important;
}

.text-white {
    color: #fff !important;
}
.text-white-secondary {
    color: rgba(#fff, 0.87) !important;
}
.text-black {
    color: #000 !important;
}
.text-black-secondary {
    color: rgba(#000, 0.87) !important;
}
.text-muted-white {
    color: rgba(#fff, 0.54) !important;
}
.text-light-white {
    color: rgba(255, 255, 255, 0.54) !important;
}
.text-muted {
    color: $text-muted !important;
}
.text-hint {
    color: $text-hint !important;
}
.text-gray {
    color: rgba(0, 0, 0, 0.74) !important;
}
.text-brand {
    color: $brand !important;
}
.text-primary {
    color: $primary !important;
}
.text-secondary {
    color: $secondary !important;
}
.text-green {
    color: #08ad6c !important;
}
.text-red {
    color: #d4280a !important;
}
.text-error {
    color: $error !important;
}

.gray-on-hover {
    transition: background 250ms ease;
    &:hover {
        background: rgba(0, 0, 0, 0.054);
    }
}

// Border color
.border-color-white {
    border-color: #ffffff !important;
}
.border-color-default {
    border-color: $bg-default !important;
}
.border-color-paper {
    border-color: $bg-paper !important;
}
.pb-24 {
    padding-bottom: 6rem !important;
}
.pt-7 {
    padding-top: 1.75rem !important;
}
.px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.p-sm-24 {
    padding: 24px !important;
}
.mt--18 {
    margin-top: -4.5rem !important;
}
.s-m-30 {
    margin: 30px;
}

.small-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;

    .small-icon {
        font-size: 8px;
    }
}
