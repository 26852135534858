.signup {
    background: #00EEEE;
    
    
    .signup-card {
        background: #8b8a8a;
      max-width: 600px;
      border-radius: 12px !important;
      img {
        width: 200px;
      }
    }
}
.signup-card {
    background-color: #eeeeee !important;
    max-width: 80%;
    border-radius: 12px !important;
    box-shadow: none !important;
}
img.signup {
    width: 80%;
    justify-content: end;
    border-radius: 12px !important;
}
